import React from "react"
import Layout from "../components/layout"

export default function About() {
    return (
        <Layout>
            <h1>about us</h1>
            <p>
                Spheemo G.K. (合同会社 Spheemo)
            </p>
            <p>
                Established: 2020/10/14<br />
                Representative: mmiy Miyasaka<br />
            </p>



        </Layout>
    )
}